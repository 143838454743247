import React from 'react';
import logo from './logo.jpg';
import './App.css';

function App() {
  return (
    <div className="container">
      <h1 className="title">Coming Soon!</h1>
      <div>
      <a href="https://www.facebook.com/letsfoodaround/"><i class="fab fa-facebook fa-4x"></i></a>
      <a href="https://www.instagram.com/_letsfoodaround36/"><i class="fab fa-instagram fa-4x"></i></a>
      </div>
      <div>
      <img className="logo" src={logo} alt="logo"/>
      </div>
    </div>

  )   
}

export default App;
